
//#region Imports
import React, { useState, useEffect, useMemo, useRef } from "react";

import { Helmet } from "react-helmet";
import Config from "../Config.json";
import { Link } from "react-router-dom";
import { useTable } from 'react-table';
import Select from "react-select";
import DatePicker, {registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//import 'bootstrap/dist/css/bootstrap.min.css';
import DateRangePickerInput from 'react-datepicker';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import axios from "axios";
import { Tooltip } from "@mui/material";
import hu from "date-fns/locale/hu";

registerLocale("hu", hu);

//#endregion



const TITLE = "Attendance | " + Config.SITE_TITLE;
const DESC = "Create a React App from an HTML Website.";
const CANONICAL = Config.SITE_DOMAIN + "/";

const Attendance = () => {

  // #region States  

  // #region Database datas

  // Datalist
  const [listOfUsers, setListOfUsers] = useState([]);

  // Value types
  const [listOfValues, setListOfValues] = useState([]);

  // Daily values
  const [listOfDailyValues, setListOfDailyValues] = useState([]);

  // Watches
  const [listOfWatches, setListOfWatches] = useState([]);

  //#endregion

  // Define states for the selected month from datepicker
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [isWorkDate, setIsWorkDate] = useState([])


  // Define states for the cells datas
  const [selectedUser, setSelectedUser] = useState("");

  // Define states for the input text
  const [inputText, setInputText] = useState("");
  const [typing, setTyping] = useState(false);

  const [selectedOption, setSelectedOption] = useState({ value: "Név", label: "Név" });
  const selectRef = useRef(null);

  // Define state for the filtered options
  const [filteredOptions, setFilteredOptions] = useState([]);

  // Define state for the selected groups
  const [selectedGroup, setSelectedGroup] = useState("Csoport");

  // Define states for popup window
  const [showPopup, setShowPopup] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedAbsence, setSelectedAbsence] = useState(null);
  const [comment, setComment] = useState(null);

  //#endregion


  // Array for group select
  const groupOptions = [
    { value: "Csoport", label: "Csoport" },
    { value: "MSE4", label: "MSE4" },
    { value: "MSE4.3", label: "MSE4.3" },
    { value: "MSE4.3-E", label: "MSE4.3-E" },
    { value: "MSE4.3-O1", label: "MSE4.3-O1" },
    { value: "MSE4.3-O2", label: "MSE4.3-O2" },
    { value: "MSE4.3-P1", label: "MSE4.3-P1" },
    { value: "MSE4.3-P1.1", label: "MSE4.3-P1.1" },
    { value: "MSE4.3-P2", label: "MSE4.3-P2" },
    { value: "MSE4.3-Q", label: "MSE4.3-Q" },
  ];

  // #region Fetches

  // Fetch user datas
  useEffect(() => {
    axios.get("http://localhost:3001/users")
      .then((response) => {
        //console.log(response.data);
        setListOfUsers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // Fetch value types
  useEffect(() => {
    axios.get("http://localhost:3001/value_types")
      .then((response) => {
        //console.log(response.data);
        setListOfValues(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // Fetch daily value for all users
  useEffect(() => {
    axios.get("http://localhost:3001/daily_value", {
      params: {
        month: selectedMonth.toLocaleString('default', { month: 'long' }) // Aktuális hónap neve
      }
    })
      .then((response) => {
        const sortedData = response.data.sort((a, b) => new Date(a.StartDate) - new Date(b.StartDate));
        setListOfDailyValues(sortedData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [selectedMonth]);

  // Fetch watches
  useEffect(() => {
    axios.get("http://localhost:3001/watches")
      .then((response) => {
        //console.log(response.data);
        setListOfWatches(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  //#endregion



  // Update the inputText state as the user types
  const handleNameInputChange = (inputValue) => {
    setInputText(inputValue);
    // If input text is empty, set typing to false and reset filtered options
    if (inputValue === "") {
      setTyping(false);
    } else {
      setTyping(true);
    }
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setInputText(selectedOption && selectedOption.value !== "Név" ? selectedOption.label.toLowerCase() : "");
  }


  const handleGroupChange = (selectedOption) => {
    setSelectedGroup(selectedOption ? selectedOption.value : "Csoport");
  }


  const handleDayClick = (day, userId) => {
    if (day > 0 && day <= getDaysInMonth(selectedMonth)) {
      document.querySelector(".attendance_header").classList.add("blur");
      document.querySelector(".table-container").classList.add("blur");
      setShowPopup(true);
      setSelectedDay(day);
      setSelectedUser(userId);
      const fullDate = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), day);
      setStartDate(fullDate);
      setEndDate(fullDate);
      console.log(fullDate);
    }
  };


  // Save button
  const handleSaveClicked = (e) => {
    if (selectedAbsence == null) {
      return (<Alert variant="filled" severity="error">
        This is a filled error Alert.
      </Alert>)
    }
    else {


      e.preventDefault();

      axios.post('http://localhost:3001/save-data-to-watches', {
        userId: selectedUser,
        startDate: startDate,
        endDate: endDate
      }).catch((err) => {
        console.error("Error fetching data:", err);
      })

      axios.post('http://localhost:3001/save-data-to-dailyValues', {
        userId: selectedUser,
        absence: selectedAbsence,
        startDate: startDate,
        endDate: endDate,
        comment: comment
      }).then(res => {
        // Fetch updated daily values after successful save
        axios.get("http://localhost:3001/daily_value", {
          params: {
            month: selectedMonth.toLocaleString('default', { month: 'long' }) // Aktuális hónap neve
          }
        })
          .then((response) => {
            const sortedData = response.data.sort((a, b) => new Date(a.StartDate) - new Date(b.StartDate));
            setListOfDailyValues(sortedData);
            //console.log(sortedData);
            setShowPopup(false);
            setSelectedDay(null);
            document.querySelector(".table-container").classList.remove("blur");
            document.querySelector(".attendance_header").classList.remove("blur");
            setStartDate(null);
            setEndDate(null);
            setComment(null)
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      })
        .catch(err => console.log(err))
    }
  }

  // Close button
  const closePopup = () => {
    document.querySelector(".table-container").classList.remove("blur");
    document.querySelector(".attendance_header").classList.remove("blur");
    setShowPopup(false);
    setSelectedDay(null);
    setStartDate(null);
    setEndDate(null);
  };


  const handleSetValue = selectedOption => {
    setSelectedAbsence(selectedOption.value);
  }

  const getDaysInMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  const handleMonthChange = (date) => {
    setSelectedMonth(date);
    const formattedDate = date.toISOString().split('T')[0]; // Format date to 'YYYY-MM-DD'
    axios.get("http://localhost:3001/isWorkDate", {
      params: {
        date: formattedDate // Ensure this matches the backend's expected parameter name
      }
    })
      .then((response) => {
        const datas = response.data;
        setIsWorkDate(datas)
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    console.log(isWorkDate)
  };



  const handleSelectBlur = () => {
    if (!selectedOption) {
      setInputText("Név");
    }
  };

  const isWeekend = (date) => {
    const day = date.getDay();
    const formattedDate = date.toISOString().split('T')[0];

    const workDateEntry = isWorkDate.find(entry => entry[0] === formattedDate);
    if (workDateEntry) {
      return workDateEntry[1] === 0;
    }
    return day === 6 || day === 0;
  }

  const getFullValueTypeName = (relevantValues) => {
    return relevantValues.map(value => {
      const valueType = listOfValues.find(val => val.name === value.valueType);
      return valueType ? `${valueType.name} - ${valueType.long_name}` : value.valueType;
    }).join(', ');
  };


  //#region Table

  const columns = useMemo(() => [
    {
      Header: 'Név',
      accessor: 'Name',
      className: 'text-center align-middle w-30 sticky-col',
    },
    {
      Header: 'Csoport',
      accessor: 'Group',
      className: 'text-center align-middle w-30 sticky-col',
    },
    {
      Header: 'Szabadság',
      accessor: 'Freedom',
      className: 'text-center align-middle w-10 sticky-col',
    },
    ...([...Array(getDaysInMonth(selectedMonth)).keys()].map(day => ({
      Header: day + 1,
      accessor: `day${day + 1}`,
      className: isWeekend(new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), day + 1)) ? 'weekend' : '',
    }))),
  ], [selectedMonth, listOfDailyValues, listOfValues]);

  const filteredData = listOfUsers.filter(item => {
    const nameMatch = selectedOption.value === "Név" || item.Name.toLowerCase().includes(inputText.toLowerCase());
    const groupMatch = selectedGroup === "Csoport" || item.Group === selectedGroup;
    return nameMatch && groupMatch;
  });
  const data = useMemo(() => filteredData.length > 0 ? filteredData : listOfUsers, [filteredData, listOfUsers]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  const renderTable = () => (
    <div className="table-container">
      <div className="table-responsive border rounded">
        <div className="table-scroll">
          <table {...getTableProps()} className="table table-bordered mb-0">
            <thead className="thead-fixed">
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()} className={column.className}>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      const day = parseInt(cell.column.Header);
                      const item = row.original;
                      const currentDate = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), day);
                      const freedomValues = listOfDailyValues.filter(value =>
                        new Date(value.StartDate).getDate() <= getDaysInMonth(selectedMonth) &&
                        new Date(value.EndDate).getDate() >= 1 &&
                        value.userId === item.Priority
                      );
                      const relevantValues = freedomValues.filter(value =>
                        currentDate >= new Date(value.StartDate) && currentDate <= new Date(value.EndDate)
                      );
                      const backgroundColor = relevantValues.length > 0 ? listOfValues.find(value => value.name === relevantValues[0].valueType)?.color : '';
                      const tooltipContent = relevantValues.length > 0
                        ? `${getFullValueTypeName(relevantValues)}${relevantValues[0].Comment ? `<br />${relevantValues[0].Comment}` : ''}`
                        : '';



                      return (
                        <td
                          {...cell.getCellProps()}
                          className={`${cell.column.className} dropdown-cell`}
                          style={{ backgroundColor: cell.column.className === 'weekend' ? '#ff0000' : backgroundColor }}
                          onClick={() => handleDayClick(day, item.Priority)}
                        >
                          {cell.render('Cell')}
                          {relevantValues.length > 0 ? (
                            <Tooltip title={<span dangerouslySetInnerHTML={{ __html: tooltipContent }} />} enterDelay={1000} leaveDelay={200}>
                              <span>{relevantValues.map(value => value.valueType).join(', ')}</span>
                            </Tooltip>
                          ) : null}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  //#endregion

  return (
    <main>
      <Helmet>
        <title>{TITLE}</title>
        <Link rel="canonical" href="{CANONICAL}"></Link>
        <meta name="description" content={DESC} />
        <meta name="theme-color" content={Config.THEME_COLOR} />
      </Helmet>

      <div id="data-container" dtype_select="1" dlr_line_id="0" className="container bigger-container">
        <div class="attendance_header row justify-content-md-center p-2 mb-0">
          <div class="col-md-4">Jelenlét</div>
          <div id="attendance_type_name" class="col-md-2"></div>
          <div className="col-md-2" ref={selectRef}>
            <Select
              className="z-index-select custom-select"
              classNamePrefix="react-select"
              label="Név"
              placeholder="Név"
              options={[
                { value: "Név", label: "Név" },
                ...listOfUsers.map(option => ({
                  value: option.WinUserID,
                  label: option.Name
                }))
              ]}
              onInputChange={handleNameInputChange}
              onChange={handleSelectChange}
              onBlur={handleSelectBlur}
            />
          </div>

          <div class="col-md-2">
            <Select
              className="z-index-select custom-select"
              classNamePrefix="react-select"
              label="Név"
              placeholder={'Csoport'}
              options={groupOptions}
              value={groupOptions.find(option => option.value === selectedGroup)}
              onChange={handleGroupChange}
            />
          </div>
          <div class="col-md-2">
            <DatePicker
              portalId="root-portal"
              selected={selectedMonth}
              onChange={handleMonthChange}
              dateFormat="yyyy-MM"
              showMonthYearPicker
              className="form-control-datepicker"
            />
          </div>
        </div>
        {<div className="container">
          <div className="row">
            <div className="col">
              <div className="table-contains">

                {showPopup && (
                  <div className="popup-container">
                    <div className="popup d-flex align-items-center justify-content-center">
                      <div className="popup-inner">
                        <div className="my-3">
                          <h5 className="text-dark">Távollét típusának kiválasztása</h5>
                        </div>
                        <hr />
                        <Select
                          placeholder={'Távollétfajta...'}
                          className="z-index-select custom-select"
                          classNamePrefix="react-select"
                          options={listOfValues.map(option => ({
                            value: option.ID,
                            label: `${option.name} - ${option.long_name}`
                          }))}
                          onChange={handleSetValue}
                          onInputChange={selectedAbsence}
                        />
                        <div className="my-3">
                          <h5 className="text-dark">Általános adatok</h5>
                        </div>
                        <hr />
                        <div style={{ position: "relative", zIndex: 10 }}>
                          <DateRangePickerInput
                            calendarStartDay={1}
                            todayButton="Ma"
                            locale="hu"
                            dropdownMode="select"
                            selected={startDate}
                            onChange={(dates) => {
                              const [start, end] = dates;
                              setStartDate(start);
                              setEndDate(end);
                            }}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            startDatePlaceholderText={startDate ? startDate.toLocaleDateString() : "Start Date"}
                            endDatePlaceholderText={endDate ? endDate.toLocaleDateString() : "End Date"}
                            dateFormat="yyyy-MM-dd"
                            className="form-control"

                          />
                        </div>
                        <br className="mb-4"></br>
                        <TextField
                          id="standard-multiline-flexible"
                          label="Megjegyzés"
                          multiline
                          rows={4}
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            setComment(e.target.value);
                          }}
                        />
                        <br className="mb-4"></br>
                        <button className="m-4" id="save-button" onClick={handleSaveClicked}>Mentés</button>
                        <button onClick={closePopup}>Bezárás</button>
                      </div>
                    </div>
                  </div>
                )}
                {renderTable()}
              </div>
            </div>
          </div>
        </div>}
      </div>
    </main>
  );
};

export default Attendance;