export function getTheme() {
  let element = document.body;
  if (element.dataset.bsTheme === "light") {
    sessionStorage.setItem("myTheme", "light");
    //document.querySelector("#flexSwitchCheckChecked").checked = true;
    //sessionStorage.setItem("isChecked", "checked");
  } else if (element.dataset.bsTheme === "dark") {
    sessionStorage.setItem("myTheme", "dark");
    //document.querySelector("#flexSwitchCheckChecked").checked = true;
    //sessionStorage.setItem("isChecked", "unchecked");
  }
  return sessionStorage.getItem("myTheme");
}

export function setThemeMain() {
  let element = document.body;
  element.dataset.bsTheme =
    sessionStorage.getItem("myTheme") === "light" ? "dark" : "light";
  /*if (sessionStorage.getItem("isChecked") === "checked") {
    document.querySelector("#flexSwitchCheckChecked").checked = true;
  } else {
    document.querySelector("#flexSwitchCheckChecked").checked = false;
  }*/
  return sessionStorage.getItem("myTheme");
}

let cont = document.body;
export function changeSizeByBtnMinus() {
  let style = window.getComputedStyle(cont, null).getPropertyValue("font-size");
  let currentSize = parseFloat(style);
  let newSize = Math.max(currentSize - 2, 12);
  cont.style.fontSize = newSize + "px";
}

export function changeSizeByBtnBasic() {
  cont.style.fontSize = "16px";
}

export function changeSizeByBtnPlus() {
  let style = window.getComputedStyle(cont, null).getPropertyValue("font-size");
  let currentSize = parseFloat(style);
  let newSize = Math.min(currentSize + 2, 26);
  cont.style.fontSize = newSize + "px";
}

// Funkció a süti beállításához
function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

// Funkció a süti lekérdezéséhez
function getCookie(name) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

// Funkció a modális ablak megjelenítéséhez
function showCookieModal() {
  const modal = document.getElementById("cookieModal");
  if (modal) {
    modal.style.display = "flex";
  }
}

// Cookie modal logika
function handleCookieModal() {
  if (!getCookie("cookieConsent")) {
    showCookieModal();
  }

  const acceptButton = document.getElementById("acceptCookies");
  const rejectButton = document.getElementById("rejectCookies");

  if (acceptButton) {
    acceptButton.addEventListener("click", () => {
      setCookie("cookieConsent", "true", 365);
      const modal = document.getElementById("cookieModal");
      if (modal) {
        modal.style.display = "none";
      }
    });
  }

  if (rejectButton) {
    rejectButton.addEventListener("click", () => {
      const modal = document.getElementById("cookieModal");
      if (modal) {
        modal.style.display = "none";
      }
    });
  }
}

// Keresd meg az elemeket, amikor a dokumentum betöltődött
document.addEventListener("DOMContentLoaded", handleCookieModal);
