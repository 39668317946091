import React, { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  getTheme,
  setThemeMain,
  changeSizeByBtnPlus,
  changeSizeByBtnMinus,
  changeSizeByBtnBasic,
} from "../scripts/main";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";

const languages = [
  {
    code: "hu",
    name: "Magyar",
    country_code: "hu",
  },
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "de",
    name: "German",
    country_code: "de",
  },
  {
    code: "es",
    name: "Español",
    country_code: "es",
  },
  {
    code: "fr",
    name: "Français",
    country_code: "fr",
  },
];

const GlobeIcon = ({ width = 24, height = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="currentColor"
    className="bi bi-globe"
    viewBox="0 0 16 16"
  >
    <path
      d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m7.5-6.923c-.67.204-1.335.82-1.887 
    1.855A8 8 0 0 0 5.145 4H7.5zM4.09 4a9.3 9.3 0 0 1 .64-1.539 7 7 0 0 1 .597-.933A7.03 7.03 
    0 0 0 2.255 4zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a7 7 0 0 0-.656 2.5zM4.847 5a12.5 
    12.5 0 0 0-.338 2.5H7.5V5zM8.5 5v2.5h2.99a12.5 12.5 0 0 0-.337-2.5zM4.51 8.5a12.5 12.5 0 0 0 
    .337 2.5H7.5V8.5zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5zM5.145 12q.208.58.468 1.068c.552 
    1.035 1.218 1.65 1.887 1.855V12zm.182 2.472a7 7 0 0 1-.597-.933A9.3 9.3 0 0 1 4.09 12H2.255a7 7 
    0 0 0 3.072 2.472M3.82 11a13.7 13.7 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5zm6.853 3.472A7 
    7 0 0 0 13.745 12H11.91a9.3 9.3 0 0 1-.64 1.539 7 7 0 0 1-.597.933M8.5 12v2.923c.67-.204 1.335-.82 
    1.887-1.855q.26-.487.468-1.068zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.7 13.7 0 0 1-.312 
    2.5m2.802-3.5a7 7 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5zM11.27 2.461c.247.464.462.98.64 
    1.539h1.835a7 7 0 0 0-3.072-2.472c.218.284.418.598.597.933M10.855 4a8 8 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4z"
    />
  </svg>
);

const MoonIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="var(--bs-warning-text-emphasis)"
    className="bi bi-moon-stars-fill"
    viewBox="0 0 16 16"
  >
    <path d="M6 .278a.77.77 0 0 1 .08.858 7.2 7.2 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277q.792-.001 1.533-.16a.79.79 0 0 1 .81.316.73.73 0 0 1-.031.893A8.35 8.35 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.75.75 0 0 1 6 .278" />
    <path d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.73 1.73 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.73 1.73 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.73 1.73 0 0 0 1.097-1.097zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z" />
  </svg>
);

const SunIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    fill="var(--bs-warning-text-emphasis)"
    className="bi bi-brightness-high"
    viewBox="0 0 16 16"
  >
    <path d="M8 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0m9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708" />
  </svg>
);

// Cookie kezelő függvények
const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

const getCookie = (name) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

const TranslatedHeader = () => {
  const { t } = useTranslation();
  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
  const headerRef = useRef(null);
  const [theme, setTheme] = useState(getTheme());
  const [icon, setIcon] = useState(MoonIcon); // Alapértelmezettként MoonIcon
  const [currentTheme, setCurrentTheme] = useState(getTheme());
  const navigate = useNavigate();

  // handle scroll event
  const handleScroll = () => {
    const header = headerRef.current.getBoundingClientRect();
    if (window.scrollY > header.top + header.height) {
      setSticky({ isSticky: true, offset: header.height });
    } else {
      setSticky({ isSticky: false, offset: 0 });
    }
  };

  // add/remove scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNavbarLinkClick = () => {
    const navbarToggler = document.querySelector(".navbar-toggler");
    if (navbarToggler) {
      const isExpanded = navbarToggler.getAttribute("aria-expanded") === "true";
      if (isExpanded) {
        navbarToggler.click();
      }
    }
  };

  const handleLanguageChange = (code) => {
    i18next.changeLanguage(code);
    const currentPath = window.location.pathname;

    // Blog oldalak kezelése
    if (currentPath.includes("/Blog")) {
      navigate(`/Blog${code.toUpperCase()}`);
    }
    // Home oldalak kezelése
    else if (currentPath.includes("/Home")) {
      navigate(`/Home${code.toUpperCase()}`);
    }
    // Contact oldalak kezelése
    else if (currentPath.includes("/Contact")) {
      navigate(`/Contact${code.toUpperCase()}`);
    }
  };
  const toggleBodyScroll = (isOpen) => {
    if (isOpen) {
      document.body.classList.add("menu-open"); // Menü nyitva, görgetés tiltása
    } else {
      document.body.classList.remove("menu-open"); // Menü zárva, görgetés engedélyezése
    }
  };

  // Hallgatás a menü nyitás/zárás eseményre
  const navbarToggler = document.querySelector(".navbar-toggler");
  if (navbarToggler) {
    navbarToggler.addEventListener("click", () => {
      const isExpanded = navbarToggler.getAttribute("aria-expanded") === "true";
      toggleBodyScroll(isExpanded);
    });
  }

  useEffect(() => {
    const handleScroll = () => {
      const navbarToggler = document.querySelector(".navbar-toggler");
      if (navbarToggler) {
        const isExpanded =
          navbarToggler.getAttribute("aria-expanded") === "true";
        if (isExpanded) {
          navbarToggler.click(); // Bezárja a menüt, ha görgetés történik
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Tisztítás az esemény eltávolításához, amikor a komponens unmountolódik
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // Tisztítás, amikor a komponens elhagyja az oldalt

  return (
    <header data-bs-theme={currentTheme}>
      <nav
        className={`sticky-header navbar navbar-expand-lg bg-body-tertiary ${
          sticky.isSticky ? "sticky" : ""
        }`}
        ref={headerRef}
        id="sticky-header"
      >
        <div className="container-fluid">
          <NavLink id="production-areas" className="navbar-brand ms-1">
            <img src="../images/gptLogo.webp" alt="gtp" className="gtp" />
            {t("Brand_name")}
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to={
                    i18next.language === "hu"
                      ? "/HomeHu"
                      : i18next.language === "de"
                      ? "/HomeDe"
                      : i18next.language === "en"
                      ? "/HomeEn"
                      : i18next.language === "es"
                      ? "/HomeEs"
                      : "/HomeFr"
                  }
                  onClick={handleNavbarLinkClick} // Bezárja a menüt kattintáskor
                >
                  {t("Site_1")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to={
                    i18next.language === "hu"
                      ? "/BlogHu"
                      : i18next.language === "de"
                      ? "/BlogDe"
                      : i18next.language === "en"
                      ? "/BlogEn"
                      : i18next.language === "es"
                      ? "/BlogEs"
                      : "/BlogFr"
                  }
                  onClick={handleNavbarLinkClick} // Bezárja a menüt kattintáskor
                >
                  {t("Site_2")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/Pcb"
                  onClick={handleNavbarLinkClick} // Bezárja a menüt
                  kattintáskor
                >
                  {t("Site_3")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/PreAssy"
                  onClick={handleNavbarLinkClick} // Bezárja a menüt
                  kattintáskor
                >
                  {t("Site_4")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/Assembly"
                  onClick={handleNavbarLinkClick} // Bezárja a menüt
                  kattintáskor
                >
                  {t("Site_5")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to={
                    i18next.language === "hu"
                      ? "/ContactHu"
                      : i18next.language === "de"
                      ? "/ContactDe"
                      : i18next.language === "en"
                      ? "/ContactEn"
                      : i18next.language === "es"
                      ? "/ContactEs"
                      : "/ContactFr"
                  }
                  onClick={handleNavbarLinkClick} // Bezárja a menüt kattintáskor
                >
                  {t("Site_6")}
                </NavLink>
              </li>
            </ul>
            <div className="d-flex align-items-center">
              <div className="dropdown">
                <button
                  className="btn btn-link dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <GlobeIcon />
                </button>
                <ul className="dropdown-menu">
                  {languages.map(({ code, name, country_code }) => (
                    <li key={country_code}>
                      <button
                        className="dropdown-item"
                        onClick={() => {
                          handleLanguageChange(code);
                          handleNavbarLinkClick(); // Bezárja a navbar-t
                        }}
                      >
                        <span
                          className={`flag-icon flag-icon-${country_code} mx-2`}
                        ></span>
                        {name}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="d-flex" role="search">
                <input
                  type="text"
                  id="header-search"
                  placeholder="Search blog posts"
                  name="s"
                />
                <button type="submit">Search</button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default TranslatedHeader;
