import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="footer-content">
          <nav className="footer-nav">
            {/*<Link to="/">Kezdőlap</Link>
            <Link to="/privacy">Adatvédelem</Link>
            <Link to="/terms">Felhasználási feltételek</Link>
            <Link to="/contact">Kapcsolat</Link>*/}
          </nav>
          <div className="footer-contact">
            <p>Kövess minket:</p>
            <div className="social-media">
              <a href="https://facebook.com">Facebook</a>
              <a href="https://linkedin.com">LinkedIn</a>
              <a href="https://twitter.com">Twitter</a>
            </div>
          </div>
          <p className="copyright">
            © 2024 Mindennapi AI. Minden jog fenntartva.
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;
