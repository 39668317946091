import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Config from "../Config.json";
import { Link, useNavigate } from "react-router-dom";

//import TimeAgo from "react-time-ago";
import { format, register } from "timeago.js"; // register szükséges a nyelv hozzáadásához
import hu from "timeago.js/lib/lang/hu"; // A magyar nyelv importálása
import de from "timeago.js/lib/lang/de"; // A magyar nyelv importálása

// Magyar nyelv regisztrálása
register("hu", hu, "de", de);
//import ReactTimeAgo from "react-time-ago";
//import { format } from "timeago.js"; // A format függvényt importáljuk

const TITLE = "DBC | " + Config.SITE_TITLE;
const DESC = "Create a React App from an HTML Website.";
const CANONICAL = Config.SITE_DOMAIN + "/";

const lastUpdatedDate = new Date(); // Ez az utolsó frissítési időpont

const BlogHu = () => {
  const navigate = useNavigate();

  const [lastUpdated, setLastUpdated] = useState(lastUpdatedDate);

  const handleLinkClick = (event) => {
    event.preventDefault();

    const linkUrl =
      "https://htvvm653.emea.bosch.com/Reportsa/powerbi/Q_csoport/IMT_EWS_FAN";

    fetch(linkUrl, { method: "HEAD" })
      .then((response) => {
        if (response.ok) {
          window.open(linkUrl, "_blank");
        } else {
          navigate("/link-open-error", {
            state: {
              errorMessage: "Unable to open the requested link.",
              errorDetails:
                "The server might be down or the link might be incorrect.",
            },
          });
        }
      })
      .catch(() => {
        navigate("/link-open-error", {
          state: {
            errorMessage: "Unable to open the requested link.",
            errorDetails:
              "The server might be down or the link might be incorrect.",
          },
        });
      });
  };

  useEffect(() => {
    // A dátum frissítése más események alapján, például API hívás után
    // setDate(újDátum);
  }, []);

  return (
    <main>
      <Helmet>
        <title>{TITLE}</title>
        <Link rel="canonical" href="{CANONICAL}"></Link>
        <meta name="description" content={DESC} />
        <meta name="theme-color" content={Config.THEME_COLOR} />
      </Helmet>
      <div className="w-50 content-center">
        <div class="card mb-3">
          <div class="row g-0">
            <div class="col-md-4">
              <img
                src="../images/fluximage2.png"
                class="img-fluid rounded-start"
                alt="..."
              />
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <h5 class="card-title">Card title</h5>
                <p class="card-text">
                  This is a wider card with supporting text below as a natural
                  lead-in to additional content. This content is a little bit
                  longer.
                </p>
                <small className="text-muted">
                  Utoljára frissítve {format(lastUpdated, "hu")}{" "}
                  {/* Magyar nyelvű formátum */}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default BlogHu;
