import React from "react";
import { Helmet } from "react-helmet";
import Config from "../Config.json";
import { Link, useNavigate } from "react-router-dom";

const TITLE = "DBC | " + Config.SITE_TITLE;
const DESC = "Create a React App from an HTML Website.";
const CANONICAL = Config.SITE_DOMAIN + "/";

const BlogEn = () => {
  const navigate = useNavigate();

  const handleLinkClick = (event) => {
    event.preventDefault();

    const linkUrl =
      "https://htvvm653.emea.bosch.com/Reportsa/powerbi/Q_csoport/IMT_EWS_FAN";

    fetch(linkUrl, { method: "HEAD" })
      .then((response) => {
        if (response.ok) {
          window.open(linkUrl, "_blank");
        } else {
          navigate("/link-open-error", {
            state: {
              errorMessage: "Unable to open the requested link.",
              errorDetails:
                "The server might be down or the link might be incorrect.",
            },
          });
        }
      })
      .catch(() => {
        navigate("/link-open-error", {
          state: {
            errorMessage: "Unable to open the requested link.",
            errorDetails:
              "The server might be down or the link might be incorrect.",
          },
        });
      });
  };

  return (
    <main>
      <Helmet>
        <title>{TITLE}</title>
        <Link rel="canonical" href="{CANONICAL}"></Link>
        <meta name="description" content={DESC} />
        <meta name="theme-color" content={Config.THEME_COLOR} />
      </Helmet>
      <div className="w-75 content-center">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
          <div className="col">
            <div className="card h-100">
              <img
                src="../images/IMT_EWS.png"
                className="card-img-top"
                alt="IMT_EWS"
              />
              <button
                onClick={handleLinkClick}
                className="btn btn-Bosch-primary"
              >
                Megnyitás
              </button>
              <div className="card-body">
                <h5 className="card-title">IMT EWS</h5>
                <p className="card-text">BlogEn</p>
              </div>
            </div>
          </div>
          {/* Add the rest of the cards here */}
        </div>
      </div>
    </main>
  );
};

export default BlogEn;
