import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";

import reportWebVitals from "./reportWebVitals";

// Import all plugins
import * as bootstrap from "bootstrap";
import "flag-icon-css/css/flag-icons.min.css";
// Or import only needed plugins
import {
  Tooltip as Tooltip,
  Toast as Toast,
  Popover as Popover,
} from "bootstrap";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

import "./App.css";
import App from "./App";

//import TimeAgo from "javascript-time-ago";

//import en from "javascript-time-ago/locale/en";
//import hu from "javascript-time-ago/locale/hu";

//TimeAgo.addDefaultLocale(en);
//TimeAgo.addLocale(hu);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["hu", "en", "de", "es", "fr"],
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)

    fallbackLng: "en",
    detection: {
      order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
    react: { useSuspense: false },
  });

const loadingMarkup = (
  <div className="py-4 text-center">
    <h2>Loading...</h2>
  </div>
);
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Suspense fallback={loadingMarkup}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
