import React from "react";
import { Helmet } from "react-helmet";
import Config from "../Config.json";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import i18next from "i18next";

const TITLE = "Home | " + Config.SITE_TITLE;
const DESC = "Create a React App from an HTML Website.";
//const CANONICAL = Config.SITE_DOMAIN + "/";

class HomeEn extends React.Component {
  render() {
    return (
      <main className="main-content">
        <Helmet>
          <title>{TITLE}</title>
          <Link rel="canonical" href="{CANONICAL}"></Link>
          <meta name="description" content={DESC} />
          <meta name="theme-color" content={Config.THEME_COLOR} />
        </Helmet>
        {/* Hero szekció */}
        <section className="hero  col-12 col-sm-12 col-md-12 col-lg-12 ">
          <h1>
            Discover how artificial intelligence is shaping everyday life life.
          </h1>
        </section>
        {/* Cookie Consent Modal */}
        <div id="cookieModal" className="cookie-modal">
          <div className="cookie-modal-content">
            <h3>The site uses cookies</h3>
            <p>
              Our website uses cookies to improve the user experience use.
              Please accept cookies for the full user experience in order to
            </p>
            <div className="cookie-buttons">
              <button id="acceptCookies" className="btn btn-primary">
                I accept it
              </button>
              <button id="rejectCookies" className="btn btn-secondary">
                I refuse
              </button>
            </div>
          </div>
        </div>
        {/* Legfrissebb bejegyzések */}
        <section className="latest-posts ">
          <h2>
            <b>Generative AI Inside Life</b>
          </h2>
          <div className="container mt-5">
            <article className="post post-left row mb-4 py-3 shadow">
              <div className="col-3">
                <img
                  className="img-fluid mt-2 mb-2"
                  src="../images/flux-tiktok.png"
                  alt="Bejegyzés kép"
                />
              </div>
              <div className="col-8 mx-2 shadow-sm rounded">
                <h3>AI Trends in 2024</h3>
                <h3>TikTok Notes, Instagram's New Competitor:</h3>
                <p>
                  TikTok has made a bold move by launching TikTok Notes, a
                  feature set to rival Instagram in the social media landscape.
                  With the power of AI-driven algorithms, TikTok aims to enhance
                  user experience, offering a new way to share quick updates and
                  challenge Instagram's dominance in the photo-sharing and
                  social interaction space...
                </p>
                <Link to="/Blogs/AITrendek">I'll keep reading</Link>
              </div>
            </article>
            <article className="post post-right row mb-4 py-3 shadow">
              <div className="col-9">
                <h3>Machine learning made easy</h3>
                <h3>
                  Learn everything about machine learning in a simple and
                  understandable way.
                </h3>

                <p>
                  Machine learning is important because it gives enterprises a
                  view of trends in customer behavior and operational business
                  patterns, as well as supports the development of new products.
                  Many of today's leading companies, such as Facebook, Google,
                  and Uber, make machine learning a central part of their
                  operations.
                </p>
                <Link to="/Blogs/AITrendek">I'll keep reading</Link>
              </div>
              <div className="col-3">
                <img
                  className="img-fluid mt-2 mb-2"
                  src="../images/machine-learning.webp"
                  alt="Bejegyzés kép"
                />
              </div>
            </article>
            <article className="post post-left row mb-3 py-3 shadow">
              <div className="col-3">
                <img
                  className="img-fluid mt-2 mb-2 "
                  src="../images/jasperai.png"
                  alt="Bejegyzés kép"
                />
              </div>
              <div className="col-9">
                <h3>AI tools for businesses</h3>
                <p>The best AI tools to help grow your business efficiency.</p>
                <p>
                  Many people know Jasper as the best AI writing assistant that
                  leads the market with its impressive features and quality.
                  First you need to provide initial words that Jasper parses,
                  before bringing phrases, paragraphs or documents created based
                  on subject matter and tone. Less than 1,500 minutes can write
                  a 15-word article under.
                </p>
                <Link to="/Blogs/AITrendek">I'll keep reading</Link>
              </div>
            </article>
          </div>
        </section>
        {/* AI Eszközök */}
        <section className="tools container">
          <h2>
            <b>Recommended AI tools</b>
          </h2>
          <div className="tools-list">
            <div className="tool-item-p">
              <img
                className="img-fluid2 mt-2 mb-2 "
                src="../images/Robovision-pink-logo-banner.jpg"
                alt="AI Eszköz Robovision-pink-logo-banner.jpg"
              />
              <h3>Computer Vision Platform Powered By AI.</h3>
              <p>Powerful AI tool for image recognition.</p>
              <p>
                Robovision offers a vision AI platform that covers the full AI
                life cycle. Streamline the entire process of developing,
                implementing and adapting AI in today’s ever-changing business
                environment. Creating applications for complex computer vision
                challenges has never been easier thanks to the power of AI and
                the Robovision Platform.
              </p>
              <Link to="/tools/tool-1">Find out more</Link>
            </div>
            <div className="tool-item-u">
              <img
                className="img-fluid2 mt-2 mb-2 "
                src="../images/freshdesk-logo.svg"
                alt="AI Eszköz Freshdesk"
              />
              <h3>
                <b>Freshdesk</b>
              </h3>
              <p>AI-based customer service solution.</p>
              <p>Deliver effortless customer service with Freshdesk</p>
              <Link to="/tools/tool-2">Find out more</Link>
            </div>
            <div className="tool-item-p">
              <img
                className="img-fluid2 mt-2 mb-2 "
                src="../images/Bard-AI-Logo.png"
                alt="AI Eszköz Bard AI"
              />
              <h3>
                <b>Bard AI</b>
              </h3>
              <p>An AI tool that helps increase productivity.</p>
              <p>
                Bard is an experimental conversational AI service developed by
                Google, powered by its Language Model for Dialogue Applications
                (LaMDA). It’s designed to provide high-quality, fresh responses
                by drawing information from the web. Bard attributes direct
                lengthy quotes to the source webpage and offers users the
                ability to view and potentially click through to the original
                source via URLs or image thumbnails for verification and further
                exploration.
              </p>
              <Link to="/tools/tool-3">Find out more</Link>
            </div>
          </div>
        </section>
        {/* Hírlevél feliratkozás */}
        <section className="newsletter container">
          <h2>Subscribe to our newsletter!</h2>
          <p>Don't miss out on the latest trends and innovations in AI.</p>
          <form>
            <input type="email" placeholder="Email address" />
            <button type="submit">Subscription</button>
          </form>
        </section>
      </main>
    );
  }
}

export default HomeEn;
