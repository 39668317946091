import React from "react";

import { Helmet } from "react-helmet";
import Config from "../Config.json";
import { Link } from "react-router-dom";

const TITLE = "PCB | " + Config.SITE_TITLE;
const DESC = "Create a React App from an HTML Website.";
const CANONICAL = Config.SITE_DOMAIN + "/";

class Pcb extends React.Component {
  render() {
    return (
      <main>
        <Helmet>
          <title>{TITLE}</title>
          <Link rel="canonical" href="{CANONICAL}"></Link>
          <meta name="description" content={DESC} />
          <meta name="theme-color" content={Config.THEME_COLOR} />
        </Helmet>
        <div className="container d-flex justify-content-center">
          <div id="container" className="container-fluid ">
            <img
              id="mse43-logo"
              src="../images/MSE43Logo (2).png"
              alt="mse43-logo"
              className="rounded img-fluid  d-block"
            />
          </div>
        </div>
        <div>
          <p>
            Հայերեն Shqip ‫العربية Български Català 中文简体 Hrvatski Česky
            Dansk Nederlands English Eesti Filipino Suomi Français ქართული
            Deutsch Ελληνικά ‫עברית हिन्दी Magyar Indonesia Italiano Latviski
            Lietuviškai македонски Melayu Norsk Polski Português Româna Pyccкий
            Српски Slovenčina Slovenščina Español Svenska ไทย Türkçe Українська
            Tiếng Việt Lorem Ipsum "Neque porro quisquam est qui dolorem ipsum
            quia dolor sit amet, consectetur, adipisci velit..." "There is no
            one who loves pain itself, who seeks after it and wants to have it,
            simply because it is pain..." Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Pellentesque dignissim tortor vitae dui
            ullamcorper, sodales semper dolor lobortis. In hac habitasse platea
            dictumst. Morbi quam ante, hendrerit id vestibulum ac, porta vel
            est. Phasellus et bibendum urna. Maecenas nisi lacus, aliquam at
            euismod sed, scelerisque sodales massa. Aenean rhoncus dui enim, non
            finibus urna bibendum quis. Suspendisse et nulla elementum,
            imperdiet nibh vel, accumsan nulla. Morbi et sapien mauris. Sed non
            metus ullamcorper velit bibendum condimentum sed non ante. Donec sit
            amet laoreet arcu, quis malesuada diam. Curabitur rutrum eros at
            tempor laoreet. Nulla ac urna augue. Nullam erat risus, tincidunt eu
            arcu vitae, pulvinar interdum odio. Quisque ullamcorper gravida est
            ac pretium. Integer est augue, venenatis non augue quis, fermentum
            facilisis felis. Nunc sit amet dignissim augue. Cras scelerisque
            massa ut eleifend tincidunt. Pellentesque habitant morbi tristique
            senectus et netus et malesuada fames ac turpis egestas. Duis
            malesuada lobortis ultrices. Quisque ac eleifend sem. Quisque quis
            mollis ligula. Proin non pulvinar odio. Nam quis dolor turpis. Duis
            mattis mauris nec interdum tristique. Nulla mollis porta justo a
            commodo. Donec quis mollis lorem. Aenean auctor luctus quam ac
            dapibus. Integer varius quis lorem ac rhoncus. Maecenas vel
            convallis purus, a dictum odio. Sed consectetur enim nulla, sed
            fermentum purus cursus quis. Donec non sodales orci. In hac
            habitasse platea dictumst. Quisque porttitor neque quis egestas
            vehicula. Sed tristique quis lectus ac mattis. Sed erat neque,
            egestas ac ante ut, laoreet interdum mi. Proin a nisl at libero
            pellentesque rutrum. Pellentesque commodo augue odio, ut viverra
            risus volutpat in. Cras pellentesque lacus felis. Phasellus eleifend
            auctor volutpat. Cras mi tellus, efficitur sit amet cursus sed,
            scelerisque vel nunc. Morbi eu vestibulum eros. Duis vel rhoncus
            tellus. Duis dignissim enim metus, ut viverra enim dapibus nec.
            Donec lobortis eget orci id dictum. Orci varius natoque penatibus et
            magnis dis parturient montes, nascetur ridiculus mus. Donec velit
            magna, pulvinar vitae efficitur tincidunt, rhoncus eget lectus.
            Fusce non nulla tincidunt, convallis ex sit amet, vestibulum odio.
            Maecenas vitae ligula vitae risus porttitor sodales bibendum
            dignissim odio. Quisque tortor urna, facilisis id nunc nec, sagittis
            condimentum urna. Pellentesque et orci iaculis, pretium eros id,
            ullamcorper elit. Sed et risus in sapien ornare vulputate. Integer
            at efficitur turpis. Aliquam erat volutpat. Class aptent taciti
            sociosqu ad litora torquent per conubia nostra, per inceptos
            himenaeos. Fusce placerat hendrerit metus. Sed egestas est erat, ac
            efficitur libero interdum eu. Suspendisse potenti. Duis faucibus
            magna ut est efficitur, vitae blandit leo feugiat. Fusce commodo
            placerat leo sed euismod. Nullam convallis, nulla nec pharetra
            ullamcorper, massa lectus congue justo, sit amet posuere ipsum enim
            ac enim. In laoreet nibh non nisi molestie, vitae luctus urna
            venenatis. Pellentesque habitant morbi tristique senectus et netus
            et malesuada fames ac turpis egestas. Nulla nec risus facilisis,
            sodales nunc id, rhoncus nisl. Maecenas sem justo, fermentum ac
            imperdiet vitae, imperdiet et ante. Donec mi magna, cursus ut
            rhoncus id, lacinia id turpis. Phasellus semper libero mauris, non
            venenatis magna malesuada a. Etiam vulputate nunc a sollicitudin
            varius. Sed mollis urna et turpis tempus, id placerat mi pharetra.
            Donec sodales consectetur pretium. Vestibulum ultricies nulla nec
            eros fringilla, sed pretium est ultrices. Nullam congue vulputate
            tortor, sed finibus felis semper vitae. Fusce sit amet mattis justo,
            eu faucibus risus. Maecenas sodales mi sit amet nibh feugiat
            rhoncus. Etiam quis ultricies ligula, nec molestie purus. Sed id
            tincidunt tortor. In ultrices leo rhoncus pharetra aliquam.
            Phasellus vel eros vel purus eleifend fringilla at sit amet urna.
            Fusce sollicitudin commodo ex, eget ultricies arcu auctor ut. Etiam
            dapibus, libero nec scelerisque fermentum, nibh orci ullamcorper
            nibh, ac viverra nibh nisl non metus. Nullam lectus augue, vulputate
            sit amet dolor vel, eleifend suscipit enim. Praesent tincidunt
            lobortis pulvinar. In et eros sed neque aliquam posuere. Duis
            elementum semper ex, ac vehicula sem bibendum eget. Integer in
            vehicula risus, maximus pellentesque nisl. Quisque ullamcorper purus
            eget velit porttitor pellentesque. Nam sed sagittis eros, nec
            aliquet nibh. Suspendisse dignissim nibh et sapien ultricies, in
            ornare justo pharetra. Nulla molestie leo malesuada elit ultrices
            euismod. Ut sit amet ultricies augue. Cras faucibus ipsum ac eros
            blandit, vel gravida ligula viverra. In a quam in ligula luctus
            ullamcorper sed eu diam. Aenean tempor mi vel est consequat tempus.
            Curabitur quis dapibus tortor. Quisque ac porttitor nulla. Integer
            sit amet ultrices lacus, sit amet varius nisl. Duis congue feugiat
            dui sit amet consectetur. Duis vel scelerisque sem. Praesent
            ultricies sapien fermentum quam accumsan malesuada. Integer mi nisl,
            ultrices ac libero eget, faucibus interdum ex. Nam at elit
            vulputate, semper odio vitae, elementum enim. Phasellus aliquam
            lacus nulla, tincidunt accumsan purus porttitor quis. Morbi congue
            sapien id lectus suscipit lobortis. Duis pellentesque mollis
            venenatis. Suspendisse tempus et mauris id porta. Praesent at
            hendrerit neque. Suspendisse diam nisl, iaculis vitae sagittis a,
            lobortis in purus. Generated 10 paragraphs, 800 words, 5444 bytes of
            Lorem Ipsum
          </p>
        </div>
      </main>
    );
  }
}

export default Pcb;
